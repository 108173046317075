.modal {
   background-color: rgba($color: #000000, $alpha: 0.6);
   position: fixed;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   z-index: 200;
   display: flex;
   align-items: center;
   justify-content: center;

   &__content {
      width: 600px;
      background-color: white;
      padding: 50px;
      position: relative;
   }

   &__close {
      position: absolute;
      top: 20px;
      right: 30px;
      padding: 0;
      border: 0;
      background-color: white;
      font-size: 30px;
      margin-bottom: 20px;
      cursor: pointer;
   }
   &__title {
      text-align: center;
      margin-bottom: 20px;
   }
}
