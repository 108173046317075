body {
   font-family: Karla, sans-serif;
   font-weight: 400;
   font-size: 20px;
   background-color: $defaultBackgroundColor;
}

body,
ul,
li,
p,
h1,
h2,
h3,
h4 {
   margin: 0;
   padding: 0;
}

ul,
li {
   list-style: none;
}

a {
   text-decoration: none;
   color: black;
}

.page-container {
   max-width: 1600px;
   margin: 0 auto;
   padding: $defaultPadding;
   @include respond(mobile) {
      padding: 70px 0;
   }
}

h1 {
   font-size: 50px;
   text-align: center;
   margin: 0 0 50px 0;
}

h2 {
   font-size: 40px;
   margin-bottom: 30px;
}

h3 {
   font-size: 30px;
   margin-bottom: 10px;
}

input,
textarea,
button,
select {
   font-family: Karla, sans-serif;
   font-size: 20px;
   color: black;
   padding: 0;
   margin: 0;
}

select {
   appearance: none;
   background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23131313%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
   background-repeat: no-repeat;
   background-position: right 0.7rem top 50%;
   background-size: 0.65rem auto;
}

.btn {
   cursor: pointer;
   border: none;
   box-shadow: $buttonShadow;
   padding: $buttonPadding;
   border-radius: 5px;
   background-color: $primaryColor;
   color: white;
}

.btn-close {
   display: none;
   background: none;
   border: none;
   color: white;
   font-size: 50px;
   cursor: pointer;
   position: absolute;
   top: 20px;
   right: 20px;
   @include respond(tabletAndMobile) {
      display: unset;
   }
}

.separator {
   height: 1px;
   background-color: grey;
}
.green {
   color: $primaryColor;
}
.orange {
   color: $secondaryColor;
}

.fadein {
   animation: fadeIn 0.5s forwards;
   opacity: 0;
}

@keyframes fadeIn {
   0% {
      opacity: 0;
   }
   100% {
      opacity: 1;
   }
}

@keyframes moveRight {
   0% {
      transform: translateX(2000px);
   }
   100% {
      transform: translateX(0px);
   }
}

@keyframes moveLeft {
   0% {
      transform: translateX(-2000px);
   }
   100% {
      transform: translateX(0px);
   }
}
