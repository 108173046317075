@mixin respond($breakpoint) {
   @if $breakpoint==smallDesktop {
      @media only screen and (min-width: 901px) and (max-width: 1200px) {
         @content;
      }
   }

   @if $breakpoint==tablet {
      @media only screen and (min-width: 601px) and (max-width: 900px) {
         @content;
      }
   }

   @if $breakpoint==mobile {
      @media only screen and (max-width: 600px) {
         @content;
      }
   }

   @if $breakpoint==tabletAndMobile {
      @media only screen and (max-width: 900px) {
         @content;
      }
   }
   @if $breakpoint==smallDesktopAndTabletAndMobile {
      @media only screen and (max-width: 1200px) {
         @content;
      }
   }
}
