.card {
   display: flex;
   flex-direction: column;
   box-shadow: $cardShadow;
   background-color: white;

   & img {
      width: 100%;
      height: 500px;
      object-fit: cover;
   }

   &__text {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      gap: 15px;
      padding: $cardPadding;
   }

   &__icon {
      color: $primaryColor;
      font-size: 100px;
   }

   &__name {
      font-weight: 600;
      font-size: 25px;
   }

   &__name,
   &__description {
      text-align: center;
   }

   &__tags {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 10px;
   }

   &__description {
      text-align: center;
   }

   &__button {
      margin-top: auto;
   }
}
