.contact {
   display: flex;
   flex-direction: column;

   &__content {
      display: flex;
      @include respond(smallDesktopAndTabletAndMobile) {
         flex-direction: column;
         gap: 15px;
      }
   }
   &__aside {
      width: 20%;
      padding: 20px;
      background-color: $primaryColor;
      border-radius: 20px;
      color: white;
      font-size: 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 50px;
      @include respond(smallDesktopAndTabletAndMobile) {
         width: unset;
         gap: 10px;
      }
      @include respond(mobile) {
         border-radius: unset;
      }
      & p {
         text-align: center;
      }
   }

   &__image {
      width: 100%;
   }

   &__section {
      flex-grow: 1;
      padding: 0 30px;
      @include respond(smallDesktopAndTabletAndMobile) {
         padding: 0;
      }
   }
   &__blocks {
      display: flex;
      flex-direction: column;
      gap: 15px;
   }
   &__block {
      padding: $cardPadding;
      background-color: white;
      border-radius: 20px;
      box-shadow: $cardShadow;
      @include respond(mobile) {
         border-radius: unset;
      }

      & .svg-inline--fa {
         font-size: 30px;
         color: $primaryColor;
      }
      & p:first-child {
         margin-bottom: 20px;
         display: flex;
         align-items: center;
         gap: 10px;
      }
   }
   &__form {
      display: flex;
      align-items: center;
      @include respond(tabletAndMobile) {
         flex-direction: column;
         gap: 20px;
      }
      & div:last-child {
         width: 30%;
         display: flex;
         align-items: center;
         justify-content: center;
      }
   }
   &__fields {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 70%;
      @include respond(mobile) {
         width: 100%;
      }
      & input,
      & textarea {
         border: 1px solid $primaryColor;
         border-radius: 10px;
         padding: 10px 15px;
      }
   }
}
