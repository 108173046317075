.gallery-preview {
   &__cards {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 12px;
      @include respond(tabletAndMobile) {
         grid-template-columns: 1fr;
      }
   }
   & .card {
      opacity: 0;
   }
   &__info .card__button {
      margin-top: 20px;
   }
}
