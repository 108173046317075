.hero {
   display: flex;
   flex-direction: row;
   gap: 75px;
   @include respond(tabletAndMobile) {
      flex-direction: column-reverse;
      gap: 25px;
   }

   &--left {
      flex-direction: row-reverse;
      @include respond(tabletAndMobile) {
         flex-direction: column-reverse;
      }
   }

   &__text {
      display: flex;
      flex-direction: column;
      @include respond(mobile) {
         padding: 0 30px;
      }
   }

   &__btn {
      align-self: center;
      margin-top: auto;
      margin-bottom: 15px;
   }

   &__image {
      width: 45%;
      flex-shrink: 0;
      box-shadow: $defaultShadow;
      max-height: 500px;
      @include respond(tabletAndMobile) {
         width: 100%;
         flex-shrink: 1;
         height: 300px;
      }
      &--rounded {
         border-radius: 25px;
         overflow: hidden;
         @include respond(mobile) {
            border-radius: unset;
         }
      }

      & img {
         object-fit: cover;
         width: 100%;
         height: 100%;
      }
   }
}
