.about {
   & p + h2 {
      margin-top: 30px;
   }

   & p {
      margin-bottom: 15px;
   }

   & h2 {
      @include respond(mobile) {
         text-align: center;
      }
   }
   &__blocks {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: calc(10px + 2vw);
      @include respond(mobile) {
         grid-template-columns: 1fr;
         padding: 0 10px;
      }
   }

   &__block {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      border: 2px solid $primaryColor;
      border-radius: 10px;
      padding: 10px 20px;

      & .svg-inline--fa {
         font-size: 30px;
         color: $primaryColor;
      }
      & p {
         color: $primaryColor;
         font-size: 25px;
         font-weight: 600;
         text-align: center;
         margin: 0;
      }
   }

   & .hero {
      margin-top: 70px;
   }
}
