.footer {
   background-color: $primaryColor;
   color: white;
   font-size: 18px;

   &__content {
      display: flex;
      justify-content: space-around;
      @include respond(mobile) {
         flex-direction: column;
         gap: 40px;
      }
   }

   & a {
      color: white;
      padding-bottom: 5px;
      border-bottom: 2px solid transparent;
      transition: border-color 0.25s;
      &:hover {
         border-color: $secondaryColor;
      }
   }

   &__block {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
   }

   & h3 {
      font-size: 23px;
   }
}
