.header {
   background-color: $primaryColor;
   font-weight: 500;
   position: sticky;
   top: 0;
   left: 0;
   right: 0;
   z-index: 100;

   &__content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 30px;
   }

   &__name {
      font-family: Rubik, sans-serif;
      font-weight: 500;
      font-size: 38px;
      color: white;
      @include respond(mobile) {
         font-size: 30px;
      }
   }

   &__nav {
      display: flex;
      gap: 4vw;

      @include respond(tabletAndMobile) {
         position: fixed;
         flex-direction: column;
         align-items: flex-start;
         gap: 30px;
         inset: 0;
         padding: 120px 60px;
         background-color: $primaryColor;
         z-index: 1000;
         &--closed {
            display: none;
         }
         &--opened {
            display: flex;
         }
      }
   }

   &__burger {
      display: none;
      font-size: 50px;
      color: white;
      @include respond(tabletAndMobile) {
         display: unset;
      }
   }

   &__link {
      color: white;
      border-bottom: 2px solid transparent;
      @include respond(mobile) {
         border-bottom: unset;
      }
      padding-top: 6px;
      padding-bottom: 5px;
      transition: border-color 0.25s;
      @include respond(tabletAndMobile) {
         font-size: 40px;
      }

      &:hover,
      &.active {
         border-color: $secondaryColor;
      }
   }
}
